<template>
    <div>
        <el-dialog :title="`${title} 活动演讲资料设置`" :visible.sync="show" width="40%">
            <el-form :model="form" :rules="rules" label-width="80px" ref="form">
                <el-form-item label="查看">
                    <el-checkbox v-model="form.needApply" label="需要报名活动并且审核通过" name="type"></el-checkbox>
                </el-form-item>
                <el-form-item label="标题" prop="title">
                    <el-col :span="18">
                        <el-input v-model="form.title" size="small"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="描述" prop="desc">
                    <!-- <el-checkbox v-model="show" label="需要报名活动并且审核通过" name="type"></el-checkbox> -->
                    <el-col :span="18">
                        <el-input v-model="form.desc" size="small"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="主图" prop="banner">
                    <up-img :action="{ action: 'activity' }" :banner.sync="form.banner"> </up-img>
                </el-form-item>
                <el-form-item label="分享图" prop="shareBanner">
                    <up-img
                        :action="{ action: 'tag' }"
                        :banner.sync="form.shareBanner"
                        tip
                        :tip_text="false"
                        theCustom="请上传比例5:4的图片，否则会影响前台显示"
                    >
                    </up-img>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submit" size="small">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import rules from "@/utils/rules.js";
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        getConfigDetail: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            show: false,
            form: {},
            rules: rules.reqAll({
                title: "请输入标题",
                desc: "请输入描述",
                banner: "请上传主图",
            }),
        };
    },
    methods: {
        async init() {
            let data = await this.getConfigDetail();
            this.show = true;
            this.form = {
                ...data,
                needApply: !!data.needApply,
            };
        },
        async submit() {
            this.$refs.form.validate(async (val, error) => {
                if (!val) {
                    let [err] = Object.values(error);
                    this.$message.error(err[0].message);
                    return;
                }
                const form = {
                    ...this.form,
                    needApply: this.form.needApply ? 1 : 0,
                };
                let { data: res } = await this.$http.post("/admin/ActivityPpt/editConfig", form);
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.show = false;
                }
            });
        },
    },
};
</script>

<style lang="less" scoped></style>
