var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project_check_list"},[_c('el-card',[_c('el-page-header',{staticStyle:{"margin-bottom":"30px"},attrs:{"content":_vm.detail.title},on:{"back":_vm.goBack}}),_c('nav',{staticClass:"input",staticStyle:{"width":"auto"}},[_c('load-button',{attrs:{"click":_vm.openEntrance}},[_vm._v(" "+_vm._s(_vm.configDetail.close ? "开启" : "关闭")+"演讲资料入口 ")])],1),_c('nav',{staticClass:"input",staticStyle:{"width":"auto"}},[_c('el-button',{attrs:{"size":"small","type":"primary","icon":"el-icon-setting"},on:{"click":_vm.setUp}},[_vm._v("设置")])],1),_c('nav',{staticClass:"input",staticStyle:{"width":"auto"}},[_c('el-button',{attrs:{"size":"small","type":"primary","icon":"el-icon-plus"},on:{"click":function($event){return _vm.add()}}},[_vm._v("添加演讲资料")])],1)],1),_c('el-card',{staticStyle:{"margin-top":"10px"}},[_c('z-table',{ref:"table",attrs:{"fullscreenLoading":""},on:{"query":_vm.queryList},model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","stripe":"","header-cell-style":_vm.rowClass}},[_c('el-table-column',{attrs:{"prop":"id","label":"ID"}}),_c('el-table-column',{attrs:{"prop":"typeName","label":"主图"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p-image',{attrs:{"src":row.banner,"auto":""}})]}}])}),_c('el-table-column',{attrs:{"prop":"title","label":"标题"}}),_c('el-table-column',{attrs:{"prop":"source","label":"来源"}}),_c('el-table-column',{attrs:{"prop":"manager","label":"编辑人员"}}),_c('el-table-column',{attrs:{"prop":"business","label":"行业"}}),_c('el-table-column',{attrs:{"prop":"views","label":"查看次数"}}),_c('el-table-column',{attrs:{"prop":"clickViews","label":"浏览量"}}),_c('el-table-column',{attrs:{"prop":"sort","label":"排序"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('Remark',{attrs:{"rowKey":"orderNum","row":row,"url":"/admin/ActivityPpt/editOrder"},on:{"getlist":_vm.getlist}})]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"查看方式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.needApply == 1)?_c('el-tag',{staticStyle:{"margin-bottom":"10px"},attrs:{"size":"small"}},[_vm._v(" 需要报名且审核 ")]):_vm._e(),_c('el-tag',{attrs:{"size":"small"}},[_vm._v(" "+_vm._s(_vm.getPayType(row))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.publishStatus == 1)?_c('el-tag',{attrs:{"size":"small","type":"success"}},[_vm._v(" 已发布 ")]):_c('el-tag',{attrs:{"size":"small","type":"danger"}},[_vm._v(" 未发布 ")])]}}])}),_c('el-table-column',{attrs:{"prop":"publishTime","label":"发布时间"}}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-popconfirm',{staticStyle:{"margin":"5px"},attrs:{"title":"是否继续操作"},on:{"confirm":function($event){return _vm.approved(row)}}},[_c('el-button',{attrs:{"slot":"reference","type":"text","icon":"el-icon-s-check","size":"mini"},slot:"reference"},[_vm._v(_vm._s(row.publishStatus == 1 ? "取消发布" : "发布"))])],1),_c('el-button',{staticStyle:{"color":"blue"},attrs:{"type":"text","icon":"el-icon-edit","size":"mini"},on:{"click":function($event){return _vm.add(row.id)}}},[_vm._v(" 编辑")]),_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text","icon":"el-icon-delete","size":"mini"},on:{"click":function($event){return _vm.onDelete(row)}}},[_vm._v("删除")]),_c('load-button',{staticClass:"green",attrs:{"type":"text","icon":"el-icon-camera","size":"mini","click":function (e) { return _vm.viewRecords(e, row); }}},[_vm._v(" 查看记录")])]}}])})],1)],1)],1),_c('Setting',{ref:"Setting",attrs:{"title":_vm.detail.title,"getConfigDetail":_vm.getConfigDetail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }