<template>
    <div class="project_check_list">
        <el-card>
            <el-page-header :content="detail.title" style="margin-bottom: 30px;" @back="goBack" />
            <nav class="input" style="width: auto">
                <load-button :click="openEntrance">
                    {{ configDetail.close ? "开启" : "关闭" }}演讲资料入口
                </load-button>
            </nav>
            <nav class="input" style="width: auto">
                <el-button size="small" type="primary" icon="el-icon-setting" @click="setUp">设置</el-button>
            </nav>
            <nav class="input" style="width: auto">
                <el-button size="small" type="primary" icon="el-icon-plus" @click="add()">添加演讲资料</el-button>
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <z-table ref="table" v-model="list" @query="queryList" fullscreenLoading>
                <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                    <el-table-column prop="id" label="ID"> </el-table-column>
                    <el-table-column prop="typeName" label="主图">
                        <template #default="{row}">
                            <p-image :src="row.banner" auto> </p-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" label="标题"> </el-table-column>
                    <el-table-column prop="source" label="来源"> </el-table-column>
                    <el-table-column prop="manager" label="编辑人员"> </el-table-column>
                    <el-table-column prop="business" label="行业"> </el-table-column>
                    <el-table-column prop="views" label="查看次数"> </el-table-column>
                    <el-table-column prop="clickViews" label="浏览量"> </el-table-column>
                    <el-table-column prop="sort" label="排序">
                        <template #default="{ row }">
                            <Remark rowKey="orderNum" :row="row" url="/admin/ActivityPpt/editOrder" @getlist="getlist">
                            </Remark>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="查看方式">
                        <template #default="{row}">
                            <el-tag size="small" v-if="row.needApply == 1" style="margin-bottom: 10px;">
                                需要报名且审核
                            </el-tag>
                            <el-tag size="small">
                                {{ getPayType(row) }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="状态">
                        <template #default="{row}">
                            <el-tag size="small" v-if="row.publishStatus == 1" type="success"> 已发布 </el-tag>
                            <el-tag size="small" v-else type="danger"> 未发布 </el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column prop="publishTime" label="发布时间"> </el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="{ row }">
                            <el-popconfirm @confirm="approved(row)" title="是否继续操作" style="margin: 5px;">
                                <el-button slot="reference" type="text" icon="el-icon-s-check" size="mini">{{
                                    row.publishStatus == 1 ? "取消发布" : "发布"
                                }}</el-button>
                            </el-popconfirm>
                            <el-button
                                type="text"
                                icon="el-icon-edit"
                                size="mini"
                                style="color: blue;"
                                @click="add(row.id)"
                            >
                                编辑</el-button
                            >
                            <el-button
                                @click="onDelete(row)"
                                type="text"
                                icon="el-icon-delete"
                                style="color: red;"
                                size="mini"
                                >删除</el-button
                            >
                            <load-button
                                type="text"
                                icon="el-icon-camera"
                                size="mini"
                                class="green"
                                :click="(e) => viewRecords(e, row)"
                            >
                                查看记录</load-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </z-table>
        </el-card>
        <Setting ref="Setting" :title="detail.title" :getConfigDetail="getConfigDetail"></Setting>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
import Setting from "./setting.vue";
export default {
    name: "activity_speaker_ppt",
    mixins: [list],
    data() {
        return {
            search: {
                keyword: "",
                
            },
            detail: {
                title: "加载中...",
            },
            configDetail: {},
        };
    },

    activated() {
        // console.log(1);
    },
    components: {
        Setting,
    },
    created() {
        this.search.activityId = this.$route.query.id;
        this.init();
    },
    methods: {
        getlist() {
            this.$refs.table.refresh();
        },
        async openEntrance(done) {
            let { data: res } = await this.$http.post("/admin/ActivityPpt/close", {
                id: this.$route.query.id,
                close: this.configDetail.close == 1 ? 0 : 1,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                await this.getConfigDetail();
                done();
            }
        },
        getPayType(row) {
            let { payType, payAmount } = row;
            let str;
            switch (payType) {
                case 0:
                    str = " 免费";
                    break;
                case 1:
                    str = `${payAmount}积分`;
                    break;
                case 2:
                    str = `${payAmount}帆币`;
                    break;
                case 3:
                    str = `${payAmount}现金`;
                    break;
                default:
                    break;
            }
            return str;
        },
        async init() {
            await this.getDetail();
            this.getConfigDetail();
        },
        setUp() {
            this.$refs.Setting.init();
        },
        add(id) {
            this.$router.push({ name: "activity_speaker_ppt_add", query: { id: this.$route.query.id, edit: id } });
        },
        async getDetail() {
            let { data } = await this.$http.get("/admin/Activity/getById", {
                params: { id: this.$route.query.id },
            });
            this.detail = data.data;
        },
        getConfigDetail() {
            return new Promise(async (resolve, reject) => {
                try {
                    let {
                        data: { data },
                    } = await this.$http.get("/admin/ActivityPpt/getConfigDetail", {
                        params: { id: this.$route.query.id },
                    });
                    this.configDetail = {
                        ...data,
                        title: data.title || this.detail.title,
                        desc: data.desc || this.detail.desc,
                        banner: data.banner || this.detail.banner,
                    };
                    resolve(this.configDetail);
                } catch (error) {
                    reject(error);
                }
            });
        },
        async queryList(val) {
            let { data: res } = await this.$http.get("/admin/ActivityPpt/getList", {
                params: { ...val, ...this.search },
            });
            if (res.errorCode == 200) {
                // this.$message.success(res.message);
                this.$refs.table.complete(res.data);
            }
        },
        async onDelete(row) {
            await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            });
            let { data: res } = await this.$http.post("/admin/ActivityPpt/del", { id: row.id });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
        },
        async approved(row) {
            let { data: res } = await this.$http.post("/admin/ActivityPpt/publish", {
                id: row.id,
                publishStatus: row.publishStatus == 1 ? 0 : 1,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
        },
        async viewRecords(done, row) {
            done();
            this.$router.push({
                name: "activity_speaker_ppt_log",
                query: {
                    id: row.id,
                },
            });
            // let { data: res } = await this.$http.post("/admin/ActivityPpt/exportExcel", {
            //     id: row.id,
            // });

            // if (res.errorCode == 200) {
            //     // this.$message.success(res.message);
            //     location.href = res.data.url;
            // }
        },
    },
};
</script>

<style lang="less" scoped>
.tag {
    display: inline-block;
    margin: 5px;
}
</style>
